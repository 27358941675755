/* Elements */

.navBar {
    border-radius: 1rem;
    position: fixed;
    right: 50%;
    left: 50%;
    transform: translate(-50%);
    bottom: 1rem;
    justify-self: center;
    padding: 0 1rem;
    width: 95%;
    height: 5rem;
    background-color: var(--green-100);
    box-shadow: 0 0 1rem 0 var(--green-200);
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    
    
    
}
.link {
    height: fit-content;
    list-style: none;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: var(--green-600);
}

.link :is(p) {
    margin: 0;
}
img {
    height: 26px;
    width: 26px;
}

.line {
    display: none;
}
@media screen and (min-with: 700px) {
    .line {
        height: 1rem;
        width: .1rem;
        background-color: var(--green-700);
    }
}



