/* Form */

.loginForm {
    position: fixed;
    bottom: 5%;
  }

  @media only screen and (min-width: 576px) {
    .loginForm{
      left: 1%;
    }
  }
  form {
    padding-bottom: 2rem;
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  label {
      display: flex;
      
  }

  input[type="email"],
  input[type="text"],
  input[type="password"],
  input[type="number"] {
      padding: .65rem .5rem;
      font-size: 1rem;
      border-style: none none solid solid;
      border-width: 2px;
      border-radius: 2px;
      border-color: var(--green-800);
      background-color: var(--green-200);
      color: var(--green-800);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  }

  @media (prefers-color-scheme: dark) {
    input[type="email"],
    input[type="text"],
    input[type="password"],
    input[type="number"] {
    background-color: var(--green-800);
    border-color: var(--green-700);
    color: var(--green-100);
    }

    input[type="email"]::placeholder,
    input[type="text"]::placeholder,
    input[type="password"],
    input[type="number"]::placeholder,
    select,
    textarea {
      color: var(--green-500);
    }
  }

  input[type="email"],
  input[type="text"],
  input[type="password"],
  input[type="number"],
  textarea,
  select {
    width: 400px;
  }

  select {
      margin: 0;
      -webkit-appearance: none;
      box-sizing: border-box;
      padding: .65rem .5rem;
      font-size: 1rem;
      border: 2px solid var(--green-200);
      border-radius: 10px;
      color: var(--green-700);
      height: auto;
      background-color: var(--green-100);
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.304 125.304"><path d="M62.652 103.895L0 21.41h125.304" fill="%23343334"/></svg>');
      background-repeat: no-repeat;
      background-size: 1rem;
      background-position: center right .5rem;
  }

  @media (prefers-color--scheme: dark) {
      select {
          background-color: var(--green-800);
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.304 125.304"><path d="M62.652 103.895L0 21.41h125.304" fill="%23ffffff"/></svg>');
          border-color: var(--green-700);
          color: var(--green-100);
      }
  }

  textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 1rem;
      border: 2px solid var(--green-200);
      color: var(--green-700);
      border-radius: 10px;
      resize: vertical;
      background-color: var(--green-100);
      box-sizing: border-box;
      padding: .65rem .5rem;

  }
 
  
  @media (prefers-color-scheme: dark) {
    textarea {
      background-color: var(--green-800);
      border-color: var(--green-700);
      color: var(--green-100);
    }
  }

  input:focus,
  select:focus,
  textarea:focus {
      outline: none;
      border-style: none none solid solid;
      border-width: 2px;
      border-radius: 2px;
      border-color: var(--focus-ring-color);
  }

  input:invalid,
  select:invalid,
  textarea:invalid {
      border: 2px solid var(--red-600);
  }

  input[type="checkbox"] {
    display: inline-block;
    height: 1rem;
    font-size: 1rem;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid var(--green-300);
    width: 1rem;
    background-color: var(--green-100);
    align-self: center;
    margin-right: 0.5rem;
  }
  
  @media (prefers-color-scheme: dark) {
    input[type="checkbox"] {
      background-color: var(--greem-800);
      border-color: var(--greem-600);
    }
  }
  
  input[type="checkbox"]:hover {
    cursor: pointer;
  }
  
  input[type="checkbox"]:checked {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="78.369" height="78.369" viewBox="0 0 78.369 78.369"><path fill="white" d="M78.05 19.015l-48.592 48.59c-.428.43-1.12.43-1.548 0L.32 40.016c-.427-.426-.427-1.12 0-1.547l6.704-6.704c.428-.427 1.12-.427 1.548 0l20.113 20.112 41.113-41.113c.43-.427 1.12-.427 1.548 0l6.703 6.704c.427.427.427 1.12 0 1.548z"/></svg>');
    background-size: contain;
    background-color: var(--green-300);
    border: 2px solid var(--green-300);
  }
  
  input[type="checkbox"]:focus-visible,
  input[type="checkbox"]:checked:focus-visible {
    border-color: var(--focus-ring-color);
  }
  
  @media (prefers-color-scheme: dark) {
    input[type="checkbox"]:checked {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="78.369" height="78.369" viewBox="0 0 78.369 78.369"><path fill="%23343434" d="M78.05 19.015l-48.592 48.59c-.428.43-1.12.43-1.548 0L.32 40.016c-.427-.426-.427-1.12 0-1.547l6.704-6.704c.428-.427 1.12-.427 1.548 0l20.113 20.112 41.113-41.113c.43-.427 1.12-.427 1.548 0l6.703 6.704c.427.427.427 1.12 0 1.548z"/></svg>');
      background-color: var(--green-100);
      border: 2px solid var(--green-100);
    }
  }

  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button {
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    border-radius: 10px;
    background-color: var(--green-400);
    border: 2px solid var(--green-400);
    color: var(--green-100);
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    line-height: initial;
    transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
      transform 200ms ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .secBtn  {
    border: none;
    background: none;
    color: var(--green-400);
  }
  
  @media (prefers-color-scheme: dark) {
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button {
      background-color: var(--green-100);
      border: 2px solid var(--green-100);
      color: var(--gray-800);
    }
  }
  
  @media (hover: hover) {
    input[type="submit"]:hover,
    input[type="reset"]:hover,
    input[type="button"]:hover,
    button:hover {
      cursor: pointer;
      background-color: var(--green-800);
    }

    .secBtn:hover {
        color: var(--green-700);
        background: none;
    }
  }
  
  @media (hover: hover) and (prefers-color-scheme: dark) {
    input[type="submit"]:hover,
    input[type="reset"]:hover,
    input[type="button"]:hover,
    button:hover {
      cursor: pointer;
      background-color: var(--green-300);
    }
  }
  
  button:focus-visible,
  input[type="submit"]:focus-visible,
  input[type="reset"]:focus-visible,
  input[type="button"]:focus-visible {
    border-color: var(--focus-ring-color);
    outline: none;
  }