
button {
    z-index: 100;
}

.header {
    width: 100%;

    margin: 2% 0 0;
    display: flex;
    flex-direction: column;
    
    align-items: center;
}

.header * {
    height: auto;
    margin: 0;
    padding: 0;
}

.pay-Status{
    
    width:  80%;
    max-width: 30rem;
    height: 4rem;

    border-radius: 1rem;   
    background: inherit;
    
    padding: 0 0rem;
    box-shadow: 0 0 1rem 0 var(--green-200);

    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
    
}

.pay-Status::before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    filter: blur(10px);
    margin: -20px; 
}


.pay-Info {
    
    width: 80%;
    max-width: 30rem;
    height: auto;
    min-height: 15rem;

    border-radius:1rem ;
    background: none;

    margin: -4rem 0 0 ;
    padding: 5rem .5rem .5rem 1rem ;
    box-shadow: 0 0 1rem 0 var(--green-200);

    display: flex;
    flex-flow: column nowrap;
    

}


.pay-btn {
    margin: 0;
    padding: 0;
    width: 20%;
    height: 100%;
    background-color:unset;
    color: var(--green-600);
    border: unset;
}

.pay-btn:hover {
    background-color: unset;
    border: unset;
    color: var(--green-700);
}





th {
    text-align: left;
}

.due {
    color: var(--red-400);
}

.main {
    width: 100%;
    height: auto;
    padding: 0 ;
    margin: 2rem 0 0 ;

    display: flex;
    flex-flow: column wrap;    
    
}

.Subcont {
    width: 80%;
    height: auto;
    padding: 2rem 0 0 ;
    margin: auto;
    
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}
.InfoCard {
    height: 10rem;
    width: 10rem;

    padding: 1rem;
    margin: 0 0 1rem 0;

    display: flex;
    flex-flow: column nowrap;
    
    border-radius: 2rem;
    background-color: var(--green-100);
    box-shadow: 0 0 1rem 0 var(--green-200);

    transform: rotate3d(0, 0, 0, 0deg);
    transition-property: transform, height, width;
    transition-duration: 1s;
    transition-delay: .2s;
}

.InfoCard:hover {
    height: 10.5rem;
    width: 10.5rem;
    transform: rotate3d(0, 0, 1, -2deg);
}


.AssetText {
    margin: 0;
}

.headline {
    margin: 0 ;
    width: 100%;
    text-align: center;

}

.headline * {

    margin: 0;
    padding: 0;
}

.index {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
    text-align: end;
}

@media (min-width: 750px){
    
}