/* Typo */

h1, h2, h3, h4, h5, h6 {
    font-family: ivyjournal, sans-serif;
    font-style: normal;
    margin: 1rem 0rem;
    
}

h1 {
  font-size: 3.052rem;
  font-weight: 600;
  letter-spacing: -0.15rem;
  line-height: 1;
}

h2 {
  font-size: 2.441rem;
  font-weight: 600;
  letter-spacing: -0.12rem;
  line-height: 1.2;
}

h3 {
  font-size: 1.953rem;
  font-weight: 600;
  letter-spacing: -0.09rem;
  line-height: 1.2;
}

h4 {
  font-size: 1.563rem;
  font-weight: 400;
  letter-spacing: -0.06rem;
  line-height: 1.3;
}

h5 {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  line-height: 1.4;
}

h6 {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
}

p {
    margin-bottom: 1.563rem;
}

p > *:last-child {
    margin-bottom: 0;
}

a {
  color: inherit;
  text-decoration: dotted;
  text-decoration-color: var(--text-decoration-color);
  -webkit-text-decoration-color: var(--text-decoration-color);
  -moz-text-decoration-color: var(--text-decoration-color);
  border-radius: 5px;
  text-decoration-thickness: 2px;    
}

@media (hover: hover) {
    a:hover {
        text-decoration-color: var(--green-800);
    }
}

@media (hover: hover) and (prefers-color-scheme: dark) {
  a:hover {
    text-decoration-color: var(--green-100);
  }
}

@media (prefers-color-scheme: dark) {
  a {
    text-decoration-color: var(--green-700);
    -webkit-text-decoration-color: var(--green-700);
  }
}

a:focus-visible {
  box-shadow: 0 0 0 2px var(--focus-ring-color);
  outline: none;
}

small {
  font-size: 0.888rem;
}

hr {
  border: 1px solid var(--green-700);
  margin: 3.052rem 0;
}