* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    overflow: hidden;
      font-size: 1rem;
      font-family: aaux-next, sans-serif;
      font-weight: 400;
      font-style: normal;
      line-height: 1.563em;
      margin: 0;
      padding: 0;
      text-decoration-skip-ink: "auto"; 
  }

  

  body {
    

    /*display: flex;*/
    width: 100%;
      padding: 0;
      /*margin: calc((100vh / 25) * 1.563) calc((100vw / 25) * 1.563);*/
      /*margin: 0;*/
      background-color: var(--green-100);
      color: var(--text-color);
      caret-color: var(--text-color);
      
    
  }

  @media (prefers-color-scheme: dark) {
      body {
          color: var(--green-100);
          background-color: var(--green-900);
          caret-color: var(--green-100);
      }
  }

  

.container{
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1% 0 ;
  flex-flow: row wrap ;
  
}


/*Buttons*/
.cpy-btn {

  background-color: unset;
  color: var(--green-600);
  font-weight: 400;
  border: unset;
}

.cpy-btn:hover {
  color: var(--green-700);
  background-color: unset;
  border: unset;
}


.copy-txt {
  
  width: 100%;
  height: min-content;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.hide {
  position: fixed;
  bottom: -100%;
 
}


.cls-btn {

  height: 2rem;
  width: 2rem;

  padding: 0;
  border-radius: 1rem;
  border-color: unset;
  background-color: inherit;
  box-shadow: inset .2rem .2rem .2rem var(--green-200);
  align-self: flex-end;
  margin-top: auto;
  margin-left: auto;
  margin: auto 0 0 auto;
}

.cls-btn:hover {


  background-color:var(--green-600);
  border-color: unset;
  box-shadow: none;
}

.vertical-Line{
  height: 80%;
  width: .1rem;
  background-color: var(--green-600);
}
