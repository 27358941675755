:root {
    --white: white;
    --green-900: #1e2f2c;
    --green-800: #324e49;
    --green-700: #466d66;
    --green-600: #598c83;
    --green-500: #73a69c;
    --green-400: #92b9b2;
    --green-300: #b1cdc8;
    --green-200: #d0e1de;
    --green-100: #eff5f4;
    --red-900: #742a2a;
    --red-800: #9b2c2c;
    --red-700: #c53030;
    --red-600: #e53e3e;
    --red-500: #f56565;
    --red-400: #fc8181;
    --red-300: #feb2b2;
    --red-200: #fed7d7;
    --red-100: #fff5f5;
    --text-decoration-color: var(--green-400);
    --text-color: var(--green-900);
    --focus-ring-color : var(--green-400);
  }